<script>
import Layout from "../../layouts/main";
import Processing from "@/components/transactions/processing";
import Pending from "@/components/transactions/pending";
import Completed from "@/components/transactions/completed";
import Rejected from "@/components/transactions/rejected";
import Cancelled from "@/components/transactions/cancelled";
import ViewTransaction from "@/components/transactions/view-transaction";

export default {
  components: {
    Layout,
    Processing,
    Pending,
    Completed,
    Rejected,
    Cancelled,
    ViewTransaction,
  },
  data() {
    return {
      transactionData: null,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    transactionSelected(val) {
      console.log("data loaded");
      this.transactionData = val;
    },
    modalClosed(val) {
      if (val) {
        this.transactionData = null;
      }
    },
  },
};
</script>

<template>
  <Layout>
    <b-tabs class="nav-tabs-custom" content-class="border border-top-0 p-4">
      <b-tab title="Pending">
        <Pending v-on:transaction-selected="transactionSelected" />
      </b-tab>
      <b-tab title="Processing">
        <Processing v-on:transaction-selected="transactionSelected" />
      </b-tab>
      <b-tab title="Completed">
        <Completed v-on:transaction-selected="transactionSelected" />
      </b-tab>
      <b-tab title="Rejected">
        <Rejected v-on:transaction-selected="transactionSelected"
      /></b-tab>
      <b-tab title="Cancelled">
        <Cancelled v-on:transaction-selected="transactionSelected" />
      </b-tab>
    </b-tabs>
    <ViewTransaction
      v-on:modalClosed="modalClosed"
      :transactionData="transactionData"
    />
  </Layout>
</template>
