import axios from "axios";
// import authHeader from '../auth-header'
import ConfigService from "../config";
import authHeader from "../auth-header"
const API_URL = ConfigService.base_url;

class TransactionServiceApi {
  updateTransaction(data) {
    return axios
      .post(API_URL + "update/transaction", 
        this.convertToFormData(data),
        {
        headers: authHeader()
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  cancelTransaction(data) {
    return axios
      .post(API_URL + "cancel/transaction", 
        this.convertToFormData(data),
        {
        headers: authHeader()
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  convertToFormData(item){
    var form_data = new FormData();
    for ( var key in item ) {
        form_data.append(key, item[key]);
    }
    return form_data;
  }
  getAllTransaction(progress,paginate=15,search=null) {
    let params={};
    if(search){
      params.search = search;
      params.paginate = paginate;
    }
    else{
      params.paginate = paginate;
      params.progress = progress;
    }
    return axios
      .get(API_URL + "admin/transactions", 
      {
        params: params,
        headers: authHeader()
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

}



export default new TransactionServiceApi();
