export default{
    buy:{
        "0":{
            label:"Waiting for Seller to ship",
            status:"pending",
            color:"#4aa3ff",
        },
        "1":{
            label:"En-route to Sheesh for authentication",
            status:"processing",
            color:"#5664d2"
        },
        "2":{
            label:"En-route to buyer",
            status:"completed",
            color:"#41b883"
        },
        "3":{
            label:"Item rejected",
            status:"rejected",
            color:"#fcb92c"
        },
        "4":{
            label:"Transaction cancelled",
            status:"cancelled",
            color:"#ff3d60;"
        }
    },
    sell:{
        "0":{
            label:"Kindly ship your item within 3 days",
            status:"pending",
            color:"#4aa3ff"
        },
        "1":{
            label:"En-route to Sheesh for authentication",
            status:"processing",
            color:"#5664d2"
        },
        "2":{
            label:"En-route to buyer",
            status:"completed",
            color:"#41b883"
        },
        "3":{
            label:"Item rejected",
            status:"rejected",
            color:"#fcb92c"
        },
        "4":{
            label:"Transaction cancelled",
            status:"cancelled",
            color:"#ff3d60"
        }

    },
    trade:{
        "1":{
            label:"Waiting for shipment",
            status:"pending",
            color:"#4aa3ff"
        },
        "2":{
            label:"Received",
            status:"processing",
            color:"#5664d2"
        },
        "3":{
            label:"Authentication",
            status:"processing",
            color:"#41b883"
        },
        "4":{
            label:"Shipping",
            status:"completed",
            color:"#fcb92c"
        },
        "5":{
            label:"Rejected",
            status:"cancelled",
            color:"#ff3d60"
        }

    },

}