<script>
import TransactionServiceApi from "@/service/api/transaction";
import Progress from "@/service/transactionType";
/**
 * Orders Component
 */
export default {
  components: {},
  data() {
    return {
      title: "Transaction processing",
      items: [
        {
          text: "Ecommerce",
        },
        {
          text: "Orders",
          active: true,
        },
      ],
      transactionProcessingData: [],
      progress: Progress,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "id", sortable: false },
        { key: "product", sortable: false },
        { key: "user", sortable: false, label: "Created By" },
        { key: "type", sortable: false },
        { key: "second_party", sortable: false, label: "Second Party" },
        { key: "amount", sortable: false },
        { key: "amount_due", sortable: false },
        { key: "progress", sortable: false },
        { key: "created_at", sortable: false },
        { key: "updated_at", sortable: false },
        { key: "status", sortable: false },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.transactionProcessingData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.transactionProcessingData.length;
    this.loadTransaction();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    loadTransaction() {
      TransactionServiceApi.getAllTransaction(2)
        .then((response) => {
          if (response.data.success) {
            this.transactionProcessingData = response.data.address.data;
            console.log(this.transactionProcessingData);
          }
        })
        .catch(() => {});
    },
    getProgress(type, id) {
      const label = this.progress[type][id].label;
      const status = this.progress[type][id].status;
      const color = this.progress[type][id].color;
      const progress = `${label}<br/>
      <div style="background-color:${color};font-size:12px;color:white;display:inline-block;padding:2px 5px;border-radius:5px;">${status}</div>`;
      return progress;
    },
    transactionSelected(val) {
      if (val) {
        this.$emit("transaction-selected", val);
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <b-table
                class="table-centered"
                :items="transactionProcessingData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                @row-clicked="transactionSelected"
                selectable
              >
                <template v-slot:cell(product)="row">
                  <div>
                    {{ row.item.size.product.name }}
                  </div>
                  <div>Size:{{ row.item.size.size }}</div>
                </template>
                <template v-slot:cell(user)="row">
                  <div>{{ row.value.name }}</div>
                </template>
                <template v-slot:cell(created_at)="row">
                  <div>
                    {{ moment(row.value).format("MMMM Do YYYY, h:mm:ss a") }}
                  </div>
                </template>
                <template v-slot:cell(updated_at)="row">
                  <div>
                    {{ moment(row.value).format("MMMM Do YYYY, h:mm:ss a") }}
                  </div>
                </template>
                <template v-slot:cell(status)="row">
                  <div>
                    <b-badge
                      variant="success"
                      class="ml-1"
                      v-if="row.value === 'active'"
                      >{{ row.value }}</b-badge
                    >
                    <b-badge variant="danger" class="ml-1" v-else>{{
                      row.value
                    }}</b-badge>
                  </div>
                </template>
                <template v-slot:cell(progress)="row">
                  <div v-html="getProgress(row.item.type, row.value)"></div>
                </template>

                <template v-slot:cell(second_party)="row">
                  <div v-if="row.item.buyer">
                    <div>
                      {{ row.item.buyer.user.name }}
                    </div>
                    <b-badge>Buyer</b-badge>
                  </div>
                  <div v-else>
                    <div>
                      {{ row.item.seller.user.name }}
                    </div>
                    <b-badge> Seller</b-badge>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
